.micro_mall_page {
  background:rgba(255,255,255,1);
  margin-top: 0.89rem;
  .banner_img {
    width: 7.5rem;
    height: 3.4rem;
  }
  .section1 {
    margin-top: 0.58rem;
    padding-bottom: 0.96rem;
    .list {
      margin-top: 0.54rem;
      display: flex;
      flex-wrap: wrap;
      margin-left: 0.43rem;
    }
    .item {
      width:3.2rem;
      height:3.46rem;
      background:rgba(255,255,255,1);
      box-shadow:0px 0.02rem 0.16rem 0px rgba(0,0,0,0.1);
      border-radius:1px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0.32rem;
      .imgbg {
        margin-top: 0.28rem;
        width: 2.6rem;
        height: 1.7rem;
      }
      .title {
        font-size:0.28rem;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(46,46,46,1);
        margin: 0.22rem 0.18rem;
      }
      .desc {
        width: 2.7rem;
        font-size:0.22rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        text-align: center;
        line-height:0.26rem;
      }
    }
  }
  .section2 {
    .list {
      margin-top: 0.77rem;
    }
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 1.64rem;
    }
    .imgbg {
      width: 5.22rem;
      height: 4.02rem;
    }
    .title {
      font-size:0.28rem;
      font-family:PingFangSC-Medium,PingFang SC;
      font-weight:500;
      color:rgba(51,51,51,1);
      margin: 0.49rem 0 0.13rem;
    }
    .desc {
      font-size:0.24rem;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(153,153,153,1);
      line-height:0.32rem;
    }
  }
}