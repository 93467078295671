.clickpage{
    width:6.60rem;
    height:3.00rem;
    background:rgba(255,255,255,1);
    box-shadow:0rem 0.02rem 0.28rem 0rem rgba(0,0,0,0.1);
    border-radius:0.08rem;
    margin: 0 auto;
    overflow: hidden;
    .clicktitle{
    
        margin: 0.92rem auto;
        font-size:0.28rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(51,51,51,1);
    }
    .clickok{
        font-size:0.28rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(0,153,252,1);
    }
}