.microOperationPage{
    margin-top: 0.89rem;
    background:rgba(255,255,255,1);
    
    .microOperationpage{
         width: 100%;
         height: 3.40rem;
         background-image: url('https://wdfiles.panshi101.com/website/wap/microoperation/header.png');
         background-size: cover;

    }
    .titleBlock{
        padding: 0.60rem 0 0.72rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    .title{
        height:0.34rem;
        font-size:0.36rem;
        font-family:FZLTZHK--GBK1-0,FZLTZHK--GBK1;
        font-weight:700;
        color:rgba(51,51,51,1);
    }
    .line{
       width:0.42rem;
       height:0.04rem;
       background:rgba(51,51,51,1);
       margin-top: 0.21rem;
    }
    .direction{
        display: block;
        width: 6.93rem;
        height: 4.84rem;
        margin: 0 auto;
        margin-bottom: 0.20rem;
        
    }
    .solutionImgList {
      position: relative;
      width: 7.03rem;
      height: 5.52rem;
      margin: 0.3rem auto 0;
      .solutionImg {
        position: absolute;
        width: 3.45rem;
        height: 2.7rem;
        background-size: cover;
      }
      .solutionImg1 {
        left: 0;
        top: 0;
      }
      .solutionImg2 {
        right: 0;
        top: 0;
      }
      .solutionImg3 {
        left: 0;
        bottom: 0;
      }
      .solutionImg4 {
        right: 0;
        bottom: 0;
      }
    }
    .fissionList{
        margin: 0 auto;
        width: 7.20rem;
        display: flex;
        flex-wrap: wrap;
        .operationitem{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
        }
    .picture{
        width: 3.00rem;
        height: 3.00rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .title{
        font-size:0.28rem;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:700;
        color:rgba(67,141,255,1);
        margin: 0.20rem auto;
          

        }
        .content{
            font-size:0.22rem;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:500;
            color:rgba(153,153,153,1);
           width:3.00rem;
           margin-bottom: 0.40rem;
           line-height: 0.30rem;
           
        }
    }
    .wxcodeWrap {
      width: 100%;
      overflow: hidden;
    }
    .wxcodeWrapList {
      // padding: 0 0.18rem;
      // width: 32.4rem;
      padding-left: 0.18rem;
      display: flex;
      width: 100%;
      overflow: scroll;
    }
    // chrome 和Safari
    .wxcodeWrapList::-webkit-scrollbar { width: 0 !important }
    // IE 10+
    .wxcodeWrapList { -ms-overflow-style: none; }
    // Firefox
    .wxcodeWrapList { overflow: -moz-scrollbars-none; }
    .moreBtnWrap {
      width:3.08rem;
      height:0.7rem;
      background:rgba(255,255,255,1);
      border-radius:0.35rem;
      border:0.02rem solid rgba(51,159,237,1);
      text-align: center;
      line-height: 0.7rem;
      margin: 0 auto;
      font-size:0.28rem;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(51,158,238,1);
    }
    
  
}