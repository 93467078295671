.am-carousel-wrap-dot > span {
  width:0.2rem;
  height:0.04rem;
  background:rgba(255,255,255,1);
  opacity:0.5;
  border-radius: 0;
}
.am-carousel-wrap-dot-active > span {
  width:0.32rem !important;
  height:0.04rem !important;
  background:rgba(255,255,255,1) !important;
  opacity: 1 !important;
}
.slider-decorator-0 {
  bottom: 0.2rem !important;
}
    .am-modal-transparent {
        width: auto !important;
    }
    .am-modal-transparent .am-modal-content .am-modal-body {
      padding: 0 !important;
    }
    .am-modal-content {
      padding-top: 0 !important;
    }