.answer_list_page {
  background: white;
  padding: 0.89rem 0.24rem 0;
  .title {
    font-size:0.36rem;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:600;
    color:rgba(51,51,51,1);
    line-height:0.5rem;
    padding: 0.37rem 0.06rem 0.3rem;
  }
  .question_item_wrap {
    z-index: 9999;
    transform: translateZ(100px);
    // height:1.59rem;
    background:rgba(255,255,255,1);
    border-radius:0.02rem;
    position: fixed;
    top: 0.89rem;
    left: 0;
    right: 0;
    padding: 0.23rem 0.29rem 0.21rem;
    .category-item-link {
      color: rgba(52,142,244,1);
    }
    // .question_item_spec {
    //   font-size:0.28rem;
    //   font-family:PingFangSC-Regular,PingFang SC;
    //   font-weight:400;
    //   color:rgba(52,142,244,1);
    //   line-height:0.4rem;
    //   // margin-bottom: 0.14rem;
    //   padding: 0.33rem 0.3rem 0;
    // }
    .show_more {
      margin: 0 auto 0;
      width:1.44rem;
      height:0.33rem;
      font-size:0.24rem;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(153,153,153,1);
      line-height:0.33rem;
    }
  }
  .question_item {
    font-size:0.28rem;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(52,142,244,1);
    line-height:0.4rem;
    margin-bottom: 0.14rem;
  }
  .category-item-link {
    color: rgba(52,142,244,1);
  }

  .question_list {
    padding: 0 0.06rem 0.21rem;

  }
  .answerList {
    .solution-item {
      position: relative;
      padding-bottom: 0.42rem;
      .solution-title {
        font-size:0.28rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:0.4rem;
        margin-bottom: 0.17rem;
      }
      .solution-content {
        font-size:0.24rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
        line-height:0.28rem;
      }
      .helper-image {
        margin: 0.24rem 0;
        width: 6.9rem;
      }
    }
  }
}