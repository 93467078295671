.menu_cmp {
  box-sizing: border-box;
  width: 5rem;
  // background: red;
  padding-left: 0.66rem;
  padding-top: 0.96rem;
  position: relative;
  .close_wrap {
    position: absolute;
    top: 0.23rem;
    right: 0.23rem;
  }
  .first_title {
    width: 100%;
    font-size:0.28rem;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:700;
    color:rgba(51,51,51,1);
    line-height: 0.4rem;
    // padding: 0.3rem 0;
  }
  .secode_title {
    font-size:0.28rem;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(102,102,102,1);
    line-height:0.4rem;
  }
  .third_title {
    font-size:0.26rem;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(153,153,153,1);
    line-height:0.37rem;
  }
  .section_item {
    // margin-bottom: 0.42rem;
    padding-left: 0.39rem;
  }
  .third_list {
    padding-left: 0.65rem;
  }
  // .second_list {
  //   padding-left: 0.95rem;
  // }
  .arrow_img {
    width: 0.22rem;
    height: 0.13rem;
  }
}