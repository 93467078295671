@import './reset.scss';
@import './pages/home.scss';
@import './components/header.scss';
@import './components/footer.scss';
@import './pages/about.scss';
@import './pages/case.scss';
@import './components/solutionCmp.scss';
@import './components/coolCarousalCmp.scss';
@import './pages/weiCommerce.scss';
@import './components/wxcodeCmp.scss';
@import './pages/microMarketing.scss';
@import './pages/microflow.scss';
@import './pages/microOperation.scss';
@import './components/titleCmp.scss';
@import './pages/microMall.scss';
@import './pages/microStation.scss';
@import './pages/smartStore.scss';
@import './pages/helpCenter.scss';
@import './components/menuCmp.scss';
@import './modifyAntd.scss';
@import'./components/clickPop.scss';
@import './pages/answerList.scss';

// #root{
//   overflow-x: hidden;
// }
.root_box {
  overflow-x: hidden;
}
.tab_wrap_new {
  position: fixed;
  top: 0.89rem !important;
  width:7.5rem;
  height:0.98rem;
  background:rgba(248,251,255,1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 999;
  transform: translateZ(100px);
  .tab_item {
    width:1.16rem;
    height:0.54rem;
    font-size:0.28rem;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(51,51,51,1);
    line-height:0.54rem;
    text-align: center;
  }
  .tab_item_active {
    background:rgba(227,239,255,1);
    border-radius:0.27rem;
  }
}
body.dialog-open {
  position: fixed;
  width: 100%;
}
