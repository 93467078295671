.aboutus {
  display: flex;
  flex-direction: column;
  margin-top: 0.89rem;
  background:rgba(255,255,255,1);


.showImg {
  width: 7.5rem;
  
}

.titleBlock {
  padding: 0.62rem 0 0.29rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  height: 0.44rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}
.line {
  width: 0.42rem;
  height: 0.04rem;
  background: rgba(51, 51, 51, 1);
  margin-top: 0.20rem;
}

.content {
  margin-top: 0.25rem;
  box-sizing: border-box;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  padding: 0 0.45rem;
  line-height: 0.30rem;
}

.service {
  padding-left: 0.56rem;
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 0.30rem;
}
}