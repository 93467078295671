.micro_station_page {
  margin-top: 0.89rem;
  background: white;
  .banner_img {
    width: 7.5rem;
    height: 3.4rem;
  }
  .section1 {
    margin-top: 0.61rem;
    padding-bottom: 0.44rem;
    .list {
      margin-top: 0.33rem;
      display: flex;
      flex-wrap: wrap;
      margin-left: 0.43rem;
    }
    .item {
      width:3.2rem;
      // height:3.46rem;
      background:rgba(255,255,255,1);
      // box-shadow:0px 0.02rem 0.16rem 0px rgba(0,0,0,0.1);
      border-radius:1px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0.56rem;
      .imgbg {
        // margin-top: 0.28rem;
        width: 1.1rem;
        height: 1.1rem;
      }
      .title {
        font-size:0.28rem;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(46,46,46,1);
        margin: 0.23rem 0.17rem;
      }
      .desc {
        width: 2.3rem;
        font-size:0.22rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        text-align: center;
        line-height:0.3rem;
      }
    }
  }
  .section2 {
    background:rgba(248,251,255,1);
    padding: 0.35rem 0.7rem 0.18rem;
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.43rem;
    }
    .item {
      width: 0.96rem;
      display: flex;
      flex-direction: column;
      align-items: center; 
      margin-bottom: 0.42rem;
      .icon_bg {
        width: 0.7rem;
        height: 0.7rem;
      }
      .title {
        font-size:0.24rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
      }
    }
  }
  .section3 {
    margin-top: 0.9rem;
    .list {
      margin-top: 0.5rem;
    }
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 1rem;
    }
    .imgbg {
      width: 4.89rem;
      height: 3.39rem;
    }
    .title {
      font-size:0.28rem;
      font-family:PingFangSC-Medium,PingFang SC;
      font-weight:500;
      color:rgba(51,51,51,1);
      margin: 0.3rem 0 0.07rem;
    }
    .desc {
      font-size:0.24rem;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(153,153,153,1);
      line-height:0.32rem;
    }
  }
}