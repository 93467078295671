.casepage{
    background:rgba(255,255,255,1);
    margin-top: 0.89rem;
    .caseImg{
         width: 100%;
         height: 3.40rem;
         background-image: url("https://wdfiles.panshi101.com/website/wap/case/header.png");
         background-size: cover;

    }
    .titleBlock{
        padding: 0.40rem 0 0.40rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    .title{
        height:0.34rem;
        font-size:0.36rem;
        font-family:FZLTZHK--GBK1-0,FZLTZHK--GBK1;
        font-weight:700;
        color:rgba(51,51,51,1);
    }
    .line{
       width:0.42rem;
       height:0.04rem;
       background:rgba(51,51,51,1);
       margin-top: 0.21rem;
    }
    .tab_wrap_box {
      width: 7.5rem;
      overflow-x: scroll;
      // position: relative;
      // .tab_wrap_bg1{
      //   width: 0.60rem;
      //   height: 0.84rem;
      //   background-image: linear-gradient(to right, rgba(255,255,255,1) , rgba(255,255,255,0.4));
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   z-index: 9999;
      //   transform: translateZ(100px);

      // }
      .tab_wrap_bg2{
        width: 0.86rem;
        height: 0.84rem;
        background-image: linear-gradient(to left, rgba(255,255,255,0.4) , rgba(255,255,255,1));
        position: absolute;
        right:0;
        top: 0;
        z-index: 9999;
        transform: translateZ(100px);

      }
    }
    .tab_wrap {
      width: 13.14rem;
      height:0.84rem;
      background:rgba(248,251,255,1);
      display: flex;
      justify-content: space-around;
      align-items: center;
      // margin-bottom: 0.33rem;
      .tab_item {
        width:1.5rem;
        height:0.5rem;
        border-radius:0.25rem;
        font-size:0.28rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height: 0.5rem;
        text-align: center;
      }
      .tab_item_active {
        background:rgba(227,239,255,1);
        border-radius:0.27rem;
        color: #0099FC !important;
      }
    }
    .solution_sec2 {
      // background: red !important;
      width:7.02rem;
      height:0.87rem;
      border-radius:0.04rem;
      background:rgba(255,255,255,0.1);
      // opacity:0.1;
      margin: 0.33rem auto 0;
      display: flex;
      // justify-content: space-between;
      z-index: 9999;
      transform: translateZ(1rem);
      .tab_item_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .tab_item {
        // width: 0.96rem;
        padding: 0 0.26rem;
        // font-size:0.28rem;
        // font-family:PingFangSC-Regular,PingFang SC;
        // font-weight:400;
        // // color:rgba(255,255,255,1);
        // color:rgba(153,153,153,1);
        text-align: center;
        // line-height: 0.87rem;
        line-height: 0.54rem;
        font-size:0.22rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
        // display: flex;
        // flex-direction: column;
        // line-height:26px;
      }
      .tab_item_active {
        font-size:0.22rem;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(0,153,252,1);
      }
      .tab_item_active:after {   
        content: "";   
        display: block;   
        width:0.42rem;
        height:0.04rem;
        background:rgba(0,153,252,1);
        border-radius:0.04rem;
        margin: 0 auto 0;
      }  
    }
    .content_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content_tit {
        font-size:0.32rem;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
      }
      .content_sub {
        width: 6.6rem;
        font-size:0.28rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
        line-height:0.4rem;
        margin: 0.4rem 0;
      }
      .content_tip {
        font-size:0.24rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
        padding: 0.23rem 0 1.25rem;
      }
    }
}