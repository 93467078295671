.smart_store_page {
  margin-top: 0.89rem;
  background:rgba(255,255,255,1);
  .banner_img {
    width: 7.5rem;
    height: 3.4rem;
  }
  .section1 {
    // background:rgba(248,251,255,1);
    padding: 0.35rem 0.7rem 0.18rem;
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.43rem;
    }
    .item {
      width: 0.96rem;
      display: flex;
      flex-direction: column;
      align-items: center; 
      margin-bottom: 0.42rem;
      .icon_bg {
        width: 0.7rem;
        height: 0.7rem;
      }
      .title {
        font-size:0.24rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
      }
    }
  }
  .section2 {
    padding-top: 0.4rem;
  }
}