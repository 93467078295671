.micro_marketing_page {
  z-index: 999;
  transform: translateZ(100px);
  margin-top: 0.89rem;
  .micromarketingpage{
         width: 100%;
         height: 3.40rem;
         background-image: url('https://wdfiles.panshi101.com/website/wap/micromarketing/header.png');
         background-size: cover;
  }
  .tab_wrap {
    width:7.5rem;
    height:0.98rem;
    background:rgba(248,251,255,1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    .tab_item {
      width:1.16rem;
      height:0.54rem;
      font-size:0.28rem;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(51,51,51,1);
      line-height:0.54rem;
      text-align: center;
    }
    .tab_item_active {
      background:rgba(227,239,255,1);
      border-radius:0.27rem;
    }
  }
  .hk_section {
    background: white;
    padding: 0.49rem 0.64rem 0;
    overflow: hidden;
    position: relative;
  }
  .icon_list {
    display: flex;
    flex-wrap: wrap;
  }
  .icon_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1.12rem;
    font-size:0.28rem;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(51,51,51,1);
    margin-right: 1.43rem;
    margin-bottom: 0.6rem;
  }
  .icon_item_2 {
    width: 1.98rem !important;
    // width: 2.18rem !important;
    margin-right: 0.3rem;
  }
  .no_margin_right {
    margin-right: 0;
  }
  .icon_item img {
    width: 0.7rem;
    height: 0.7rem;
    margin-bottom: 0.17rem;
  }
  .zh_section {
    background:rgba(248,251,255,1);
    padding: 0.49rem 0.64rem 0;
    overflow: hidden;
    position: relative;
  }
  .lb_section {
    background: white;
    padding: 0.49rem 0.39rem 0;
    overflow: hidden;
    position: relative;
  }
  .fg_section {
    background:rgba(248,251,255,1);
    padding: 0.49rem 0.39rem 0;
    overflow: hidden;
    position: relative;
  }
  .sub_title {
    margin-top: 0.11rem;
    font-size:0.22rem;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(153,153,153,1);
    text-align: center;
    line-height:0.24rem;
  }
}