.weiCommercePage{
    margin-top: 0.89rem;
    background:rgba(255,255,255,1);
    .solution{
       
    .titleBlock{
        padding: 0.60rem 0 0.20rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    .title{
        height:0.34rem;
        font-size:0.36rem;
        font-family:FZLTZHK--GBK1-0,FZLTZHK--GBK1;
        font-weight:700;
        color:rgba(51,51,51,1);
    }
    .line{
       width:0.42rem;
       height:0.04rem;
       background:rgba(51,51,51,1);
       margin-top: 0.21rem;
    }
    .content{
        height: 4.50rem;
        display: flex;
        margin: auto;
    .content_title{
        margin-bottom: 0.42rem;
        font-size:0.28rem;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:700;
        color:rgba(51,51,51,1);
    }
    .card_canves{
        margin-top: 0.83rem;
        margin-left: 0.40rem;
        margin-right: 0.60rem;

    }
    .content_list{
        font-size:0.24rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
    }
    .content_list_section{
        margin-bottom: 0.36rem;
    }
    .showImg{
        margin-top: 0.90rem;
        width: 3.52rem;
        height: 3.30rem;
    
    }
    }
    .content1{
        height:4.59rem;
        background:rgba(248,251,255,1);
        display: flex;
        margin: auto;
    
    .showImg1{
        margin: 0.63rem 0.40rem;
        width: 3.52rem;
        height: 3.30rem;

    }
    .card_canves{
        margin-top: 0.83rem;
        margin-left: 0.20rem;

    }
    .content_title2{
        margin-bottom: 0.42rem;
        font-size:0.28rem;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:700;
        color:rgba(51,51,51,1);
    }
    .content_list2{
        font-size:0.24rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
    }
    .content_list_section2{
        margin-bottom: 0.36rem;
    }
    }
    }
.scheme{
    .poker-table{
        width: 7.01rem;
        margin: auto;
        display: flex;
        flex-wrap:wrap;
    }
    .pokerMall{
        margin-right: 0.17rem;
        margin-bottom: 0.21rem;
    }
    .pokerMall2 {
      margin-bottom: 0.21rem;
    }
    .solutionItem {
      position: relative;
      .btn-box {
        position: absolute;
        width:2rem;
        height:0.54rem;
        background:rgba(255,238,221,1);
        border-radius:0.27rem;
        text-align: center;
        line-height: 0.54rem;
        left: 0.7rem;
        bottom: 0.24rem;
        font-size:0.22rem;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(255,113,33,1);
      }
    }
    .pokerServer{
        margin-right: 0.17rem;
        margin-bottom: 0.21rem;
        

    }
    .pokerImg{
        width: 3.40rem;
        height: 4.63rem;
    }
    }
    .service{
        .poker-table{
            width: 7.50rem;
            display: flex;
            flex-wrap:wrap;

        }
        .pokerImg2{
            width: 3.75rem;
            height: 2.92rem;

        }

    }
    .partner{
        .partner_img{
            display: flex;
            flex-wrap:wrap;
            margin: auto;
            padding: 0.10rem 0 0.80rem;
             background:rgba(255,255,255,1);
        }
        .pokerImg3{
            margin: 0.10rem 0.15rem;
            width: 2.20rem;
            height: 1.14rem;
        }

    }


}