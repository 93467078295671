.home_page{
     background:rgba(255,255,255,1);
     overflow-x: hidden;
     margin-top: 0.89rem;
    .homepage{
      width: 100%;
      height: 3.40rem;
      background-image: url(https://wdfiles.panshi101.com/website/wap/home/home_header.png);
      background-size: cover;
    }
    .titleBlock{
        padding: 0.60rem 0 0.52rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 0.42rem;

    }
    .title{
        height:0.34rem;
        font-size:0.36rem;
        font-family:FZLTZHK--GBK1-0,FZLTZHK--GBK1;
        font-weight:700;
        color:rgba(51,51,51,1);
    }
    .line{
       width:0.42rem;
       height:0.04rem;
       background:rgba(51,51,51,1);
       margin-top: 0.21rem;
    }
    .content{

    }
    .showImg{
        display: flex;
        width: 4.40rem;
        height: 2.80rem;
        margin: 0 auto;
        margin-bottom: 0.36rem;

    }
    .content-topic{
        text-align: center;
        margin: 0 auto;
        margin-bottom: 0.19rem;
        font-size:0.28rem;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:600;
        color:rgba(51,51,51,1);
        line-height:0.34rem;
    }
    .content-introduce{
        margin: 0 auto;
        text-align: center;
        height:0.64rem;
        width:5.50rem;
        font-size:0.24rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
        line-height:0.32rem;
        margin-bottom: 1.44rem;
    }
    .operationlist{
        margin: 0 auto;
        width: 7.26rem;
        display: flex;
        flex-wrap: wrap;
        .operationitem{
            width: 3.63rem;
            height: 3.23rem;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .picture{
            width: 0.94rem;
            height: 0.90rem;
            margin-top: 0.63rem;



        }
        .title{
            margin: auto 0.30rem;
            font-size:0.28rem;
            font-family:PingFangSC-Medium,PingFang SC;
            font-weight:600;


        }
        .content{
            font-size:0.24rem;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
           width:2.88rem;
           margin-bottom: 0.40rem;
           text-align: center;
           line-height:0.30rem;
        }
    }
    .wxcodeWrap {
      width: 100%;
      overflow: hidden;
    }
    .wxcodeWrapList {
      // padding: 0 0.18rem;
      // width: 32.4rem;
      padding-left: 0.18rem;
      display: flex;
      width: 100%;
      overflow: scroll;
    }
    // chrome 和Safari
    .wxcodeWrapList::-webkit-scrollbar { width: 0 !important }
    // IE 10+
    .wxcodeWrapList { -ms-overflow-style: none; }
    // Firefox
    .wxcodeWrapList { overflow: -moz-scrollbars-none; }
    .moreBtnWrap {
      width:3.08rem;
      height:0.7rem;
      background:rgba(255,255,255,1);
      border-radius:0.35rem;
      border:0.02rem solid rgba(51,159,237,1);
      text-align: center;
      line-height: 0.7rem;
      margin: 0 auto;
      font-size:0.28rem;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(51,158,238,1);
      margin-bottom: 0.44rem;
    }
}
