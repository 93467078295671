.footer_com {
  width: 7.5rem;

  .modal {
    width: 6.6rem;
    height: 3rem;
  }
  .section1 {
    position: relative;
    .section1_img {
      width: 100%;
      min-width: 7.5rem;
    }
    .img_content {
      position: absolute;
      top: 36%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .img_content_word {
      font-size: 0.32rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      margin-bottom: 0.1rem;
      text-align: center;
    }
    .img_content_tip {
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);

      margin-bottom: 0.19rem;
    }
    .img_content_btn {
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1.6rem;
      height: 0.48rem;
      background: rgba(255, 255, 255, 1);
      border-radius: 0.24rem;
      color: rgba(51, 162, 236, 1);
      font-size: 0.24rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      text-align: center;
      line-height: 0.48rem;
    }
  }
  .foot_bg {
    width: 7.5rem;
    height: 2.02rem;
    background: linear-gradient(
      90deg,
      rgba(45, 220, 214, 1) 0%,
      rgba(52, 146, 242, 1) 100%
    );
    background-size: cover;
  }
  .section2 {
    width: 100%;
    height: 3.58rem;
    background: rgba(245, 245, 245, 1);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    padding-top: 0.32rem;
    padding-bottom: 0.3rem;
    .footer_content {
      height: 2.26rem;
      display: flex;
      margin: 0 auto;
      padding-left: 0.32rem;
    }
    .footer_menu {
    }
    .footer_menu_title {
      width: 4.13rem;
      padding: 0 0 0.25rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 700;
      color: rgba(51, 51, 51, 1);
    }
    .footer_menu_list {
      width: 4.13rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
    }
    .footer_menu_item {
      margin-bottom: 0.15rem;
    }
    .img_wrap {
      display: inline-block;

      width: 0.7rem;
      height: 0.96rem;
      font-size: 0.22rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      position: relative;
    }
    .bigImg {
      width: 4rem;
      height: 4rem;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 0.08rem;
      position: absolute;
      top: -3.58rem;
      // left: -3.57rem;
      left: -3.2rem;
      display: flex;
      flex-direction: column;

      .img_wrap_applebig {
        margin: 0.35rem auto;
        width: 2.79rem;
        height: 2.79rem;
      }
      .xcx {
        margin: 0 auto;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
      }
    }
    .bigImg2 {
      left: -4.3rem !important;
    }
    .img_wrap_apple {
      width: 0.7rem;
      height: 0.7rem;
    }
    .footer_componey_info {
      height: 1.31rem;
      margin: auto 0.3rem;
      border-top: 0.02rem solid #dddddd;

      font-size: 0.22rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
      line-height: 0.2rem;
      text-align: center;
    }
    .footer_componey1 {
      margin: 0.25rem auto 0.14rem;
      width: 6.89rem;
    }
    .footer_componey2 {
      margin: 0 auto;
      margin-bottom: 0.23rem;
      width: 5.43rem;
      .icp {
        margin-top: 0.1rem;
      }
    }
  }
}
