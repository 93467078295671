.title_cmp {
  display: flex;
  flex-direction: column;
  align-items: center;
  .word {
    margin-bottom: 0.12rem;
    line-height: 0.44rem;
    font-size:0.36rem;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:rgba(51,51,51,1);
  }
  .line {
    width:0.42rem;
    height:0.04rem;
    background:rgba(51,51,51,1);
  }
}