.microflow_page{
    background:rgba(255,255,255,1);
    margin-top: 0.89rem;
    
    .microflowpage{
         width: 100%;
         height: 3.40rem;
         background-image: url('https://wdfiles.panshi101.com/website/wap/microflow/flow_header.png');
         background-size: cover;
    }
    .microflow-container{
        height: 9rem;
    .titleBlock{
        padding: 0.40rem 0 0.50rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    .title{
        height:0.88rem;
        font-size:0.36rem;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:700;
        color:rgba(51,51,51,1);
        text-align: center;
        line-height: 0.44rem;
    }
    .line{
       width:0.42rem;
       height:0.04rem;
       background:rgba(51,51,51,1);
       margin-top: 0.21rem;
    }
    .market_list {
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
    .market_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin:0.50rem 0.40rem;
    }
    .marketImg{
        width: 1.20rem;
        height: 1.20rem;
    }
    .market_item_title {
      margin: 0.18rem auto;
      font-size:0.28rem;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(51,51,51,1);
      line-height:0.28rem;
    }
    .market_item_content {
        width:2.95rem;
        font-size:0.24rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
        line-height:0.32rem;
        text-align: center;
    }
 }
 }
 .microflow-container2{
     background:rgba(246,246,246,1);
     height:8.03rem;
     overflow: hidden;
    

     .flux{
         width:2.24rem;
         padding: 0.39rem 0 0.20rem;
         font-size:0.28rem;
         font-family:PingFangSC-Medium,PingFang SC;
         font-weight:700;
         color:rgba(247,112,0,1);
         line-height:0.40rem;
         margin: 0 auto;
     }
     .section2_bg1{
         display: block;
         width: 5.18rem;
         height: 4.66rem;
         margin: 0 auto;
        

     }
     .section2_left{
         width:7.02rem;
         height:1.88rem;
         background:rgba(255,255,255,1);
         border-radius:0.08rem;
         opacity:0.56;
         margin: 0 auto;
         margin-top: 0.23rem;
         display: flex;
        .section2_list{
           display: flex;

        }
        .section2_item{
            width: 1.80rem;
            height: 1.60rem;
            display: block;
            margin: 0.25rem;
        }
        .section2_item_title{
            font-size:0.24rem;
            font-family:PingFangSC-Medium,PingFang SC;
            font-weight:700;
            color:rgba(51,51,51,1);
            margin-bottom: 0.32rem;

        }
        .section2_item_content_list{
            font-size:0.24rem;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(153,153,153,1);
            line-height:0.32rem;
        }

     }
      }
      
     .microflow-container3{
         height:8.03rem;
         overflow: hidden;
    

     .flux{
         width:2.24rem;
         padding: 0.39rem 0 0.20rem;
         font-size:0.28rem;
         font-family:PingFangSC-Medium,PingFang SC;
         font-weight:700;
         color:rgba(74,167,255,1);
         line-height:0.40rem;
         margin: 0 auto;
     }
     .section2_bg1{
         display: block;
         width: 5.18rem;
         height: 4.66rem;
         margin: 0 auto;
        

     }
     .section2_left{
         width:7.02rem;
         height:1.88rem;
         background:rgba(248,251,255,1);
         border-radius:0.08rem;
         opacity:0.56;
         margin: 0 auto;
         margin-top: 0.23rem;
         display: flex;
        .section2_list{
           display: flex;

        }
        .section2_item{
            width: 1.80rem;
            height: 1.60rem;
            display: block;
            margin: 0.25rem;
        }
        .section2_item_title{
            font-size:0.24rem;
            font-family:PingFangSC-Medium,PingFang SC;
            font-weight:700;
            color:rgba(51,51,51,1);
            margin-bottom: 0.32rem;

        }
        .section2_item_content_list{
            font-size:0.24rem;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(153,153,153,1);
            line-height:0.32rem;
        }

    }
}
.generalize{
    height: 7.20rem;
    .titleBlock{
        padding: 0.40rem 0 0.50rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    .title{
        font-size:0.28rem;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:700;
        color:rgba(51,51,51,1);
    }
    .generalizeImg{
        display: block;
        width: 7.03rem;
        height: 5.19rem;
        margin: 0 auto;

    }
}
}