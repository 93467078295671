.helpCenterpage{
    margin-top: 0.89rem;
    background:rgba(255,255,255,1);
    .helpCenterImg{
        width: 100%;
         height: 3.40rem;
         background-image: url("https://wdfiles.panshi101.com/website/wap/help/header.png");
         background-size: cover;

    }
    .helpCenterList{
        height: 6.44rem;
       
    }
    .am-list-item .am-list-line-multiple .am-list-content{
        font-size:0.28rem !important;
        font-family:PingFangSC-Regular,PingFang SC !important;
        font-weight:500 !important;
        color:rgba(102,102,102,1) !important;
        line-height:0.40rem !important;

    }
    .am-list-item .am-list-line-multiple{
        padding: 0.38rem 0.41rem 0.24rem 0 !important;
    }
}