.cool_carousal_cmp {
  width: 100%;
  height: 5rem;
  // height: 4.27rem;
  overflow: hidden;


}
.space-carousel {
  padding: 16px;
  background: rgba(255,255,255,1);
  overflow: hidden;
}