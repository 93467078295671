.header_com {
    position: fixed;
    top: 0;
    width:7.5rem;
    height:0.89rem;
    background:rgba(245,245,245,1);
    z-index: 999;
    transform: translateZ(100px);
    overflow-x: hidden;
}
.logo{
    position: relative;
    position: absolute;
    width: 1.45rem;
    height: 0.38rem;
    margin: 0.24rem 5.83rem 0.28rem 0.24rem;

}
.menu_icon{
    position: relative;
    position: absolute;
    width: 0.36rem;
    height:0.36rem;
    margin: 0.24rem 0.20rem 0.31rem 6.94rem;
}

