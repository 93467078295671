.wxcode_cmp {
  width: 5.2rem;
  // height: 5.7rem;
  height: 5.47rem;
  box-shadow:0px 0.02rem 0.16rem 0px rgba(0,0,0,0.1);
  border-radius: 0.01rem;
  position: relative;
  .wxcode_content {
    padding: 0.21rem 0.23rem 0 0.16rem;
  }
  .wxcode_title {
    font-size:0.28rem;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:rgba(51,51,51,1);
    margin-bottom: 0.16rem;
  }
  .wxcode_desc {
    line-height: 1.32rem;
    width:4.8rem;
    font-size:0.24rem;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(153,153,153,1);
    line-height:0.33rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
  .wxcode_sm_img_wrap {
    width: 0.97rem;
    position: absolute;
    top: 2.51rem;
    right: 0.25rem;
    .tip_wrap {
      z-index: 999;
      height: 0.32rem;
      line-height: 0.32rem;
      font-size:0.18rem;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(153,153,153,1);
    }
  }
  .wxcode_wrap {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // opacity: 0;
    background:rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wxcode_big_img {
    width: 2.48rem;
  }
}