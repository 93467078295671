.solution_cmp {
  width:7.5rem;
  height:10.6rem;
  background-size: cover;
  .solution_sec1 {
    width:100%;
    height:0.5rem;
    font-size:0.38rem;
    font-family:FZLTZHK--GBK1-0,FZLTZHK--GBK1;
    font-weight:normal;
    color:rgba(255,255,255,1);
    text-align: center;
    padding: 0.34rem 0 0.24rem;
  }
  .solution_sec2 {
    width:7.02rem;
    height:0.87rem;
    border-radius:0.04rem;
    background:rgba(255,255,255,0.1);
    // opacity:0.1;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    z-index: 9999;
    transform: translateZ(1rem);
    .tab_item {
      width: 0.96rem;
      font-size:0.28rem;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(255,255,255,1);
      text-align: center;
      line-height: 0.87rem;
      // background: red;
    }
    .tab_item_active {
      font-size:0.32rem !important;
      font-family:PingFangSC-Medium,PingFang SC !important;
      font-weight:500 !important;
    }
    .tab_item_active:after {   
      content: "";   
      display: block;   
      width:0.42rem;
      height:0.04rem;
      background:rgba(255,255,255,1);
      border-radius:0.04rem;
      margin: -0.14rem auto 0;
    }  
  }
  .solution_sec3 {
    .solution_desc_wrap {
      padding: 0 0.3rem 0 0.4rem;
      .sub_title {
        font-size:0.24rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(255,255,255,1);
      }
      .desc {
        font-size:0.24rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:0.4rem;
      }
      .advantage_list {

      }
      .advantage_item {
        margin-top: 0.26rem;
        font-size:0.24rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(255,255,255,1);
      }
    }
    .solution_icon_list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.48rem;
      .solution_icon_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1rem;
        margin: 0 0.4rem 0.42rem;
      }
      .solution_icon_item_img {
        width: 0.46rem;
        height: 0.46rem;
        margin-bottom: 0.2rem;
      }
      .solution_icon_item_title {
        font-size:0.24rem;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(255,255,255,1);
      }
    }
  }
}